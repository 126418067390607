<template>
  <div>
    <div
      class="summary-item section-container-white"
      @click="handlerAction"
      :class="states"
    >
      <div class="summary-item-container">
        <div class="summary-item-container-title" :class="titleClass">
          {{ title }}
        </div>
        <slot name="content-summary"></slot>
      </div>
      <div class="summary-item-icon icon-display" :class="icon"></div>
    </div>
    <div class="body-small text-red margin-bottom-sm margin-left" v-if="errorText && state === 'error'">
      {{ errorText }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'SummaryItem',
  props: {
    icon: String,
    title: String,
    titleClass: {
      type: String,
      default: 'small-tag'
    },
    state: {
      type: String,
      default: ''
    },
    errorText: String
  },
  methods: {
    handlerAction () {
      if (this.state === 'disabled') {
        return
      }

      this.$emit('callback')
    }
  },
  computed: {
    states () {
      return this.state
    }
  }
}
</script>

<style lang="sass" scoped>
.section-container-white
  display: flex
  justify-content: space-between
  align-items: center
  margin-bottom: 10px
  transition: 0.5 ease-out-in border box-shadow
  &.disabled
    opacity: 1
    background: var(--gray-5)
    border: none
    box-shadow: none
    &.summary-item-icon
      opacity: 0
  &.error
    border: 2px solid var(--red-20)
    box-shadow: 0px 4px 16px rgba(254, 74, 73, 0.2)
.summary-item
  display: flex
  gap: 8px
  justify-content: space-between
  align-items: center
  &-container
    display: flex
    flex-direction: column
    gap: 9px
    &-title
      transition: all .5s

.small-tag
  font-size: var(--xs)
  line-height: 13px
  color: var(--gray-50)
.icon-display
  color: var(--gray-80)

@media (min-width: 840px)
  .small-tag
    font-size: var(--sm)
    line-height: 13px
    color: var(--gray-50)

</style>
