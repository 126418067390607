<template>
  <SummaryItem
    :title="title"
    :titleClass="onfocus()"
    :class="{ 'is-focus': isFocus }"
  >
    <template v-slot:content-summary>
      <textarea
        data-hj-suppress
        v-model="valuePriv"
        id="textarea"
        class="textarea"
        :placeholder="$translations.diary.textarea.placeholder"
        @input="resize($event)"
        @focus="focusEvent(true)"
        @blur="focusEvent(false)"
      />
    </template>
  </SummaryItem>
</template>

<script>
import SummaryItem from '@/views/appointments/components/SummaryItem'

export default {
  name: 'DiaryTextarea',
  components: {
    SummaryItem
  },
  model: {
    props: 'value',
    event: 'change'
  },
  props: {
    value: String,
    title: String
  },
  data () {
    return {
      isFocus: false,
      valuePriv: null,
      textHeight: null
    }
  },
  mounted () {
    const count = document.getElementById('textarea').value.length

    if (count > 0) {
      const value = Math.ceil(count / 56)

      document
        .getElementById('textarea')
        .style.setProperty('height', `${value * 24}px`, 'important')
    }
  },
  methods: {
    resize (event) {
      event.target.style.setProperty('height', '25px', 'important')
      event.target.style.setProperty(
        'height',
        `${event.target.scrollHeight}px`,
        'important'
      )

      this.$emit('change', this.valuePriv)
    },
    onfocus () {
      this.$emit('change', this.valuePriv)
      if (this.valuePriv?.length > 0) {
        return 'small-tag text-gray-50'
      }

      return this.isFocus ? 'small-tag text-gray-50' : 'content-title'
    },
    focusEvent (val) {
      this.isFocus = val
      this.$emit('is-focus', this.isFocus)
    }
  },
  watch: {
    value: {
      handler (val) {
        this.valuePriv = val
      },
      immediate: true
    }
  }
}
</script>

<style lang="sass" scoped>
::v-deep .summary-item
  background-color: var(--white)
  border: 2px solid var(--gray-10)
  box-shadow: 0px 8px 12px rgba(141, 145, 160, 0.1)
  transition: all .5s

  &-container
    width: 100%
    max-height: 188px
    overflow: auto
  &-icon
    display: none

.is-focus
  ::v-deep .summary-item
    border: 2px solid #C3C8D5 !important
    box-shadow: 0px 0px 0px 4px #DDE1E9 !important

.textarea
  width: 100%
  border: unset !important
  padding: 0 !important
  height: 25px !important
  box-sizing: border-box
  background-color: var(--white) !important
</style>
