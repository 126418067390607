<template>
  <DialogContainer
    :title="config.title"
    icon="icon-trash-on text-red"
    icon-color="red"
    :custom-icon="true"
    contentIconBackground="bg-red-20"
    @closeDialog="$emit('close-modal')"
  >
    <template v-slot:content>
      <div class="body-big">
        {{ config.description }}
      </div>
      <Button
        class="margin-top"
        v-for="button in buttons"
        :key="button.key"
        :text="button.text"
        :type="button.type"
        :color="button.color"
        :disable="button.disabled"
        @clicked="hdlClickOptions(button.key, button.segmentEvent)"
      />
    </template>
  </DialogContainer>
</template>

<script>
import { DialogContainer, Button } from '@seliaco/red-panda'
import { DiaryEvents } from '@/types/events'

export default {
  name: 'DiaryModalConfirm',
  components: {
    DialogContainer,
    Button
  },
  props: {
    isDisabledSaveButton: Boolean
  },
  data () {
    const translation = this.$translations.diary
    const config = {
      title: translation.modal.title,
      description: translation.modal.description
    }

    const buttons = [
      {
        key: 'exit',
        text: translation.button.exit,
        type: 'block',
        color: 'red',
        disabled: false,
        segmentEvent: {
          callback: () => DiaryEvents.Exit_Confirm()
        }
      },
      {
        key: 'save',
        text: translation.button['exit-save'],
        type: 'block',
        color: 'purple',
        disabled: this.isDisabledSaveButton,
        segmentEvent: {
          callback: () => DiaryEvents.Exit_Save()
        }
      },
      {
        key: 'back',
        text: translation.button.back,
        type: 'outline',
        color: 'gray',
        disabled: false,
        segmentEvent: {
          callback: () => DiaryEvents.Exit_Discard()
        }
      }
    ]

    return {
      config,
      buttons
    }
  },
  methods: {
    hdlClickOptions (key, segmentEvent) {
      this.$emit('action', key)
      segmentEvent.callback()
    }
  }
}
</script>

<style lang="sass" scoped>
.heading-mate
  text-align: center
::v-deep .icon-display
  font-size: 34px
</style>
