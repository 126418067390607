<template>
  <!-- DEPRECATED -->
  <div class="diary-modal">
    <div @click="handlerClick" class="diary-modal-backdrop"></div>

    <div ref="modal" class="diary-modal-container modal-in">
      <!-- header -->
      <div class="diary-modal-header cursor-pointer" v-if="title">
        <!-- arrow back -->
        <div
          class="icon-arrow-full-outline-left icon-display"
          @click="handlerClick"
        ></div>

        <!-- title -->
        <div class="heading-small" v-text="title" />

        <!-- save action -->
        <div
          class="content-title text-purple cursor-pointer"
          @click="handlerClick"
          v-text="$translations['suggested-specialists'].dialog.button"
        />
      </div>
      <!-- content -->
      <div class="diary-modal-content" :class="{ 'without-title': !title }">
        <div class="diary-modal-slot">
          <slot name="content"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DiaryModal',
  props: {
    title: String,
    id: String
  },
  methods: {
    handlerClick () {
      this.$refs.modal.classList.remove('modal-in')
      this.$refs.modal.classList.add('modal-out')
      if (this.id) {
        this.$emit('save', this.id)
      }

      setTimeout(() => {
        this.$emit('close')
      }, 450)
    }
  }
}
</script>

<style lang="sass" scoped>
.diary-modal-backdrop
  position: fixed
  background: rgba(0, 0, 0, 0.4)
  height: 100vh
  width: 100vw
  top: 0
  right: 0
  left: 0
  bottom: 0
  z-index: 98

.diary-modal-container
  border-radius: 16px 16px 0 0
  width: 100%
  z-index: 998
  background-color: var(--gray-5)
  position: fixed
  left: 0
  right: 0
  bottom: 0
  display: grid
  transform-origin: center

.modal-in
  animation: modalIn .5s ease-in-out

.modal-out
  animation: modalOut .5s ease-in-out

.diary-modal-header
  border-radius: 16px 16px 0 0
  border-bottom: 1px solid var(--gray-20)
  background: var(--white)
  height: 100%
  padding: 16px 12px
  display: grid
  align-items: center
  gap: 16px
  grid-template-columns: min-content 1fr min-content

.diary-modal-content
  height: 400px
  background: var(--gray-5)
  &.without-title
    border-radius: 16px 16px 0 0
    padding: 20px 0

.diary-modal-slot
  padding: 16px
  height: 100%
  background: white
  overflow: auto

@media (min-width: 840px)
  .modal-in
    animation: modalInCenter .5s ease-in-out

  .modal-out
    animation: modalOutCenter .5s ease-in-out

  .diary-modal-container
    box-shadow: var(--shadow-gray)
    max-width: 480px
    border-radius: 16px
    height: max-content
    max-height: 475px
    min-height: 250px
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    z-index: 9998
    overflow-y: hidden

  .diary-modal-header
    padding: 15px 20px

  .diary-modal-content
    &.without-title
      height: unset
      .diary-modal-slot
        max-height: unset !important

  .diary-modal-slot
    max-height: 395px

@keyframes modalIn
  0%
    transform: translateY(100%)
  100%
    transform: translateY(0%)

@keyframes modalOut
  0%
    transform: translateY(0%)
  100%
    transform: translateY(100%)

@keyframes modalInCenter
  0%
    top: 25%
    left: 25%
    transform: scale(0, 0) translate(-50%, -50%)
  100%
    top: 50%
    left: 50%
    transform: scale(1, 1) translate(-50%, -50%)

@keyframes modalOutCenter
  0%
    top: 50%
    left: 50%
    transform: scale(1, 1) translate(-50%, -50%)
  100%
    top: 25%
    left: 25%
    transform: scale(0, 0) translate(-50%, -50%)
</style>
