<template>
  <div class="diary-entry-date" @click="$emit('clicked')">
    <div class="content-title" v-text="getText" />
    <div v-if="!edit" class="icon-arrow-small-outline-down" />
  </div>
</template>

<script>
export default {
  name: 'DiaryEntryDate',
  props: {
    value: Date,
    edit: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    getText () {
      const valueDate = this.$moment(this.value)
      let text = ''
      if (
        valueDate.isSame(this.$moment(), 'day') &&
        valueDate.isSame(this.$moment(), 'month')
      ) {
        text = `${this.$translations.time.today},`
      }

      return `${text} ${this.$moment(this.value).format('DD MMMM')}`
    }
  }
}
</script>

<style lang="sass" scoped>
.diary-entry-date
  display: flex
  gap: 9px
  padding: 8px 12px
  align-items: center
  background: var(--gray-5)
  border: 1px solid var(--gray-20)
  margin-bottom: 8px
  border-radius: 8px
  width: fit-content
  cursor: pointer
</style>
