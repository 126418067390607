<template>
  <div>
    <PageContainer
      v-if="!isDone"
      :title="title"
      :back="actions.back"
      :has-shy-header="true"
      :ellipsis="true"
      :submit="submit"
    >
      <template v-slot:content>
        <div class="segment">
          <div class="segment-content">
            <DiaryEntryDate
              :class="{ disable: $route.query.action === 'edit' }"
              :value="
                $route.query.action === 'edit' ? form.created_at : form.date
              "
              :edit="$route.query.action === 'edit'"
              @clicked="setModal"
            />

            <!-- moodbanner-->
            <MoodBanner :is-entry-type="true" class="margin-bottom-lg" />

            <!-- items -->
            <template v-for="(item, index) in items">
              <DiaryTextarea
                v-if="item.type === 'TEXTAREA'"
                :key="index"
                :title="item.title[lang]"
                v-model="form[item.id]"
                @is-focus="hideButtonMobile"
                @change="safeData('feeling_tex_area')"
              />
              <DiaryEntryItem
                v-if="item.type === 'MULTISELECT_EMOJI'"
                :title="item.title[lang]"
                :is-empty="form[item.id]?.length > 0"
                :key="index"
                @clicked="setModal(item)"
              >
                <template v-slot:content>
                  <div
                    class="diary-entry-chips"
                    v-if="form[item.id]?.length > 0"
                  >
                    <Chip
                      class="diary-emoji"
                      :emoji="emoji.emoji"
                      :text="emoji.name[lang]"
                      :color="item.id === 'feeling_tags' ? 'purple' : 'blue'"
                      size="small"
                      v-for="(emoji, index) in form[item.id]"
                      :key="index"
                      emoji-size="12px"
                    />
                  </div>
                </template>
              </DiaryEntryItem>
            </template>
          </div>
        </div>
      </template>
    </PageContainer>

    <!-- modal close -->
    <DiaryModalConfirm
      @action="handlerModalActions"
      :is-disabled-save-button="submit.disabled"
      v-if="showConfirmModal"
    />

    <!-- modal -->
    <DiaryModal
      :id="modalData.id"
      :title="modalData.title"
      @close="closeModal"
      @save="safeData"
      v-if="modalData.show"
    >
      <template v-slot:content>
        <DatePicker
          v-if="modalData.type === 'DATEPICKER'"
          @date-select="closeModal"
          :max-date="new Date()"
          v-model="form[modalData.id]"
        />
        <MultiselectEmojiField
          v-if="modalData.type === 'MULTISELECT_EMOJI'"
          v-model="form[modalData.id]"
          :lang="lang"
          :options="modalData.options"
        />
      </template>
    </DiaryModal>

    <DiaryContainer
      v-if="isDone"
      :actions="doneActions"
      :top-bar-config="{ color: 'transparent', 'box-shadow': 'none', position: 'absolute' }"
      max-width="1008px"
    >
      <template v-slot:banner>
        <div class="diary-finish">
          <div class="diary-finish-content">
            <img
              class="diary-finish-image margin-bottom"
              width="56px"
              :src="successImg"
              :alt="$translations.diary.success.title"
            />
            <div v-html="$translations.diary.success.title" class="diary-finish-title margin-bottom heading" />
            <div v-html="$translations.diary.success.description" class="diary-finish-description body" />
          </div>
        </div>
      </template>
      <template v-slot:content>
        <div
          class="page-segment"
          :class="{ 'margin-top': !isMobile, 'padding-top-xs': isMobile }"
          v-if="entries && entries.length"
        >
          <div class="page-segment-content">
            <div class="heading-small margin-bottom text-gray-80" v-text="$translations.diary.success.recommendations" />
            <div class="diary-entry-cards">
              <DisplayCardLarge
                v-for="(item, index) in other"
                :key="`other-${index}`"
                :display-config="otherConfig(item)"
                @click="onClick(item, 'other')"
              />
              <DisplayCardLarge
                v-for="(item, index) in entries"
                :key="index"
                :display-config="displayConfig(item)"
                :duration-icon="item.content_format.icon"
                @click="onClick(item, 'entry')"
              />
            </div>
          </div>
        </div>
      </template>
    </DiaryContainer>
  </div>
</template>
<script>
import {
  Chip,
  DatePicker,
  Diary,
  DiaryContainer,
  DisplayCardLarge,
  MultiselectEmojiField,
  PageContainer,
  PNG,
  Settings
} from '@seliaco/red-panda'

import MoodBanner from './components/MoodBanner'
import DiaryModal from './components/DiaryModal'
import DiaryEntryDate from './components/DiaryEntryDate'
import DiaryTextarea from './components/DiaryTextarea'
import DiaryEntryItem from './components/DiaryEntryItem'
import DiaryModalConfirm from './components/DiaryModalConfirm'
import {DiaryEvents, ForYouEvent} from '@/types/events'
import RecommendationBanner from "@/views/diary/components/RecommendationBanner.vue";
import MediaContentMixin from "@/mixins/media-content-mixin";
import ResponsiveMixin from "@/mixins/responsive.mixin";
import {mapGetters} from "vuex";

const COMPONENTS = {
  MoodBanner,
  DiaryModal,
  DiaryEntryDate,
  DiaryTextarea,
  DiaryEntryItem,
  DiaryModalConfirm,
  DiaryContainer,
  DatePicker,
  MultiselectEmojiField,
  PageContainer,
  Chip,
  RecommendationBanner,
  DisplayCardLarge
}

export default {
  name: 'DiaryEntry',
  mixins: [MediaContentMixin, ResponsiveMixin],
  components: {
    ...COMPONENTS
  },
  data () {
    return {
      items: null,
      showConfirmModal: false,
      modalData: {
        show: false,
        title: null,
        type: null,
        options: null,
        id: null
      },
      actions: {
        back: {
          callback: () => this.exit()
        }
      },
      submit: {
        text:
          this.$route.query.action === 'edit'
            ? this.$translations.diary.button.save
            : this.$translations.diary.button.add,
        callback: this.addEntry,
        loading: false,
        disabled: true
      },
      form: {
        feeling_tex_area: null,
        feeling_score: null,
        activity_tags: null,
        feeling_tags: null,
        date: new Date(),
        created_at: null
      },
      successImg: PNG.Success,
      isDone: false,
      doneActions: {
        close: {
          icon: 'icon-close',
          callback: () => {
            this.$router.push({ name: 'Diary' })
            this.closeEvent()
          }
        }
      },
      entries: null,
      other: null
    }
  },
  mounted () {
    this.getSetting()
  },
  destroyed () {
    localStorage.removeItem('selia-diary-entry-form')
  },
  methods: {
    closeEvent () {
      const mood = this.data?.feeling_score ?
        this.data.feeling_score :
        this.form.feeling_score
      const entriesMapped = this.entries.map(entry => ({
        title: entry.title,
        category: entry.category,
        type: entry.type,
        mood
      }))
      const othersMapped = this.other.map(other => ({
        title: other.title,
        mood
      }))
      DiaryEvents.diaryRecommendationClosed({
        user: { id: this.user.id, gender: this.user.gender },
        recommendations: [...entriesMapped, ...othersMapped]
      })
    },
    async getSetting () {
      const options = await Settings.get('EMOTION_DAILY_FORM')
      this.items = options.parse_value

      if (localStorage.getItem('selia-diary-entry-form')) {
        const localStorageItem = JSON.parse(
          localStorage.getItem('selia-diary-entry-form')
        )
        Object.keys(localStorageItem).map((item) => {
          if (item === 'created_at') {
            this.form[item] = this.$moment(localStorageItem[item]).toDate()
          } else {
            this.form[item] = localStorageItem[item]
          }
        })
      }
    },
    setModal (item) {
      if (item) {
        this.modalData = {
          show: true,
          title: item.modalTitle[this.lang],
          type: item.type,
          options: item.options,
          id: item.id
        }
      } else {
        this.modalData = {
          show: true,
          type: 'DATEPICKER',
          id: 'date'
        }
      }
    },
    closeModal () {
      this.modalData.show = false
    },
    safeData (key) {
      const localStorageItem = JSON.parse(
        localStorage.getItem('selia-diary-entry-form')
      )

      if (localStorageItem) {
        localStorage.setItem(
          'selia-diary-entry-form',
          JSON.stringify({ ...localStorageItem, [key]: this.form[key] })
        )
      } else {
        localStorage.setItem(
          'selia-diary-entry-form',
          JSON.stringify({ [key]: this.form[key] })
        )
      }
    },
    addEntry () {
      this.submit.loading = true
      let body = {
        my_day: this.form.feeling_tex_area,
        feeling_score: this.form.feeling_score,
        activity_tags: this.form.activity_tags,
        feeling_tags: this.form.feeling_tags,
        created_at: this.form.date
      }
      if (this.$route.query.action === 'edit') {
        body = {
          ...body,
          created_at: this.form.created_at
        }
      }

      const method =
        this.$route.query.action === 'edit'
          ? 'updateEmotionEntry'
          : 'createEmotionEntry'
      const id =
        this.$route.query.action === 'edit'
          ? JSON.parse(localStorage.getItem('selia-diary-entry-form')).id
          : null

      let segmentBody = {
        user: this.$store.getters['auth/user'],
        origin: 'Create',
        date: body.created_at,
        activities: body.activity_tags,
        emotions: body.feeling_tags,
        mood: body.feeling_score
      }
      if (this.$route.query.action === 'edit') {
        segmentBody = {
          ...segmentBody,
          origin: 'Edit'
        }
      }
      DiaryEvents.Diary_Submit(segmentBody)

      Diary[method](body, id)
        .then(async (o) => {
          // send analytics
          let segmentBody = {
            user: this.$store.getters['auth/user'],
            origin: 'Create',
            date: body.created_at,
            activities: body.activity_tags,
            emotions: body.feeling_tags,
            mood: body.feeling_score
          }
          if (this.$route.query.action === 'edit') {
            segmentBody = {
              ...segmentBody,
              origin: 'Edit'
            }
          }
          DiaryEvents.Diary_FillUp_Successful(segmentBody)

          // request recommended entries
          const checkInId = o?.id || id
          await Diary.getForYouRecommendations(checkInId)
            .then(o => {
            this.other = o.other
            this.entries = o.entries
          })
        })
        .catch((e) => {
          this.$toast({
            text: e.message || this.$translations.error.default,
            severity: 'error'
          })
        })
        .finally(() => {
          this.isDone = true
      })
    },
    routeBack () {
      if (this.$route.query.back) {
        this.$router.push({ name: this.$route.query.back })
      } else {
        this.$router.go(-1)
      }
    },
    exit () {
      if (this.$route.query.action === 'edit') {
        this.routeBack()
      } else {
        this.showConfirmModal = true
      }
    },
    handlerModalActions (action) {
      if (action === 'exit') {
        this.$router.push({ name: this.$route.query.back })
      }

      if (action === 'save') {
        this.addEntry()
      }

      this.showConfirmModal = false
    },
    hideButtonMobile ($event) {
      if (window.innerWidth < 1040) {
        this.submit.callback = $event ? null : this.addEntry
      }
    },
    onClick (item, type) {
      switch (type){
        case 'other':
          DiaryEvents.diaryRecommendationClicked({
            type: 'APPOINTMENT',
            key: item.key,
            tags: null
          })
          this.$router.push(item.redirect_path)
          break
        case 'entry': {
          DiaryEvents.diaryRecommendationClicked({
            type: 'CONTENT',
            key: item.slug,
            tags: item.tags
          })
          const body = {
            page: this.$route.name,
            origin: 'Diario',
            component: 'Card',
            ...item
          }
          ForYouEvent.contentCardOpen(body)
          this.showDetail(item, { back: '/diary' })
          break
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      user: "auth/user"
    }),
    lang () {
      return this.$store.getters.language.lang
    },
    title () {
      return this.$route.query.action === 'edit'
        ? this.$translations.diary.edit
        : this.$translations.diary['mood-banner'].heading
    }
  },
  watch: {
    $route: {
      handler (route) {
        if (route.query.mood) {
          const val = JSON.parse(route.query.mood)
          this.form.feeling_score = val.score
        }
      },
      immediate: true
    },
    form: {
      handler (form) {
        this.submit.disabled = !form.feeling_score
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style lang="sass">
.diary-entry-cards
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  gap: 8px
.diary-entry-chips
  display: flex
  width: 100%
  flex-wrap: wrap
  gap: 4px
.disable
  background: var(--gray-10) !important
  border: 0 !important
  pointer-events: none
.diary-finish
  width: 100%
  &-image
    margin: 0 auto
  &-content
    padding-bottom: 24px
    max-width: 448px
    margin: 0 auto
    text-align: center
</style>
