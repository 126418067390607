<template>
  <SummaryItem
    :icon="setIcon"
    :title="title"
    :titleClass="setTitleClass"
    @callback="$emit('clicked')"
  >
    <template v-slot:content-summary>
      <slot name="content"></slot>
    </template>
  </SummaryItem>
</template>

<script>
import SummaryItem from '@/views/appointments/components/SummaryItem'

export default {
  name: 'DiaryEntryItem',
  components: {
    SummaryItem
  },
  props: {
    title: String,
    isEmpty: Boolean
  },
  computed: {
    setIcon () {
      return !this.isEmpty ? 'icon-plus' : 'icon-edit-off'
    },
    setTitleClass () {
      return !this.isEmpty ? 'content-title' : 'small-tag text-gray-50'
    }
  }
}
</script>
